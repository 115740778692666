export default function windowOpen(url)
{

  let width = 550
  let height = 400

  const config = {
    height,
    width,
    location: 'no',
    toolbar: 'no',
    status: 'no',
    directories: 'no',
    menubar: 'no',
    scrollbars: 'yes',
    resizable: 'no',
    centerscreen: 'yes',
    chrome: 'yes',
    top: window.outerHeight / 2 + (window.screenY || window.screenTop || 0) - height / 2,
    left: window.outerWidth / 2 + (window.screenX || window.screenLeft || 0) - width / 2
  };

  const shareDialog = window.open(
    url,
    '',
    Object.keys(config)
      .map(key => `${key}=${config[key]}`)
      .join(', '),
  );

  return shareDialog;
}